import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/components/authentication/Login.vue";
import ServicesNavigation from "@/components/navigation/ServicesNavigation.vue"

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      title: "Sign In",
      requiresAuth: false,
      layout: "authentication"
    }
  },
  {
    path: "/login/choose-service",
    name: "Login",
    component: ServicesNavigation,
    meta: {
      title: "Pick a service",
      requiresAuth: false,
      layout: "authentication"
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../components/recovery/ResetPassword.vue"),
      meta: {
        title: "Change Your Password",
        requiresAuth: false,
        layout: "authentication"
      }
  },
  {
    path: "/self-registration",
    name: "SelfRegistration",
    component: () =>
      import("../components/registration/SelfRegistration.vue"),
      meta: {
        title: "Create an Account",
        requiresAuth: false,
        layout: "authentication"
      }
  },
  {
    path: "/recover-account",
    name: "RecoverAccount",
    component: () =>
      import("../components/recovery/RecoverAccount.vue"),
      meta: {
        title: "Reset Password",
        requiresAuth: false,
        layout: "authentication"
      }
  },
  {
    path: "/confirm-account",
    name: "ConfirmAccount",
    component: () =>
      import("../components/registration/ConfirmAccount.vue"),
      meta: {
        title: "Confirming Your New Account",
        requiresAuth: false,
        layout: "authentication"
      }
  },
  {
    path: "/profile-update",
    name: "ProfileUpdate",
    component: () =>
      import("../components/registration/ProfileUpdate.vue"),
      meta: {
        title: "Update Profile",
        requiresAuth: false,
        layout: "authentication"
      }
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import("../components/authentication/Logout.vue"),
      meta: {
        title: "Sign out",
        requiresAuth: false,
        layout: "authentication"
      }
  },
  {
    path: "/access-denied",
    name: "AccessDenied",
    component: () =>
      import("../components/core/general/AccessDenied.vue"),
      meta: {
        title: "Access Denied",
        requiresAuth: false,
        layout: "authentication"
      }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach(async (to, from, next) => {
  
  /* EXAMPLES */
  /* SAML
    * https://auth.myaileron.org/
    * ?RelayState=%2Fdashboard
    * &commonAuthCallerPath=%2Fsamlsso&forceAuth=false
    * &passiveAuth=false&tenantDomain=carbon.super
    * &sessionDataKey=d33ea54e-f8e4-4a39-b903-c2218b332587
    * &relyingParty=https%3A%2F%2Fuat.aileron.org%2Fwp-content%2Fplugins%2Fminiorange-saml-20-single-sign-on%2F
    * &type=samlsso
    * &sp=uat.aileron.org&isSaaSApp=false
    * &authenticators=BasicAuthenticator%3ALOCAL
  */

  /* OIDC
    * https://auth.myaileron.org/
    * ?client_id=QEWhF3hTzcYqfRavzsz_keXaF2ca
    * &commonAuthCallerPath=%2Foauth2%2Fauthorize&forceAuth=false
    * &nonce=c86503b9aff2151c287a456327ad992a6605cf3c161937f03876fa2443500574
    * &passiveAuth=false
    * &redirect_uri=https%3A%2F%2Fshop.aileron.org%2Foauth2&response_mode=query
    * &response_type=code&scope=openid%20email%20profile%20address%20phone
    * &state=50e6c8299b694f92c97527eb44713874
    * &tenantDomain=carbon.super&sessionDataKey=53a95fb7-4bd1-45b0-a3f4-30478c3b8906
    * &relyingParty=QEWhF3hTzcYqfRavzsz_keXaF2ca
    * &type=oidc
    * &sp=Configio
    * &isSaaSApp=true
    * &authenticators=BasicAuthenticator%3ALOCAL
  */

  console.log("Path: " + to.path);
  let redirectToDashboard: boolean = false;
  if(to.path === "/") {
    /*
     * NOTE: If we are hitting the login page and no client information is passed this means they came straight to the auth app.  Let's redirect
     * the user to the dashboard so we get a valid client session.
    */
    if(typeof(to.query.type) === "undefined") {
      redirectToDashboard = true;
    }
    else if(to.query.type === "samlsso") {
      /*
       * Let's check for all the required SAML query parameters 
       */
      if (typeof (to.query.sessionDataKey) === "undefined" || to.query.sessionDataKey === null || to.query.sessionDataKey.length <= 0) {
        redirectToDashboard = true;
      }
      else if (typeof (to.query.relyingParty) === "undefined" || to.query.relyingParty === null || to.query.relyingParty.length <= 0) {
        redirectToDashboard = true;
      }
      else if (typeof (to.query.sp) === "undefined" || to.query.sp === null || to.query.sp.length <= 0) {
        redirectToDashboard = true;
      }
    }
    else if(to.query.type === "oidc") {
      /*
       * Let's check for all required OIDC query parameters
       */
      if (typeof (to.query.client_id) === "undefined" || to.query.client_id === null || to.query.client_id.length <= 0) {
        redirectToDashboard = true;
      }
    }
  }

  if (redirectToDashboard) {
    window.location.href = process.env.VUE_APP_NO_SERVICE_URL;
  }
  else {
    const requiresAuthentication: boolean = to.matched.some(record => record.meta.requiresAuth);
    if(requiresAuthentication === true) {
      /* Not implemented yet 
      const authService: IAuthService = AuthServiceFactory.GetAuthService();
      
      if(await authService.isLoggedIn() === true) {
        let accessAllowed: boolean = await authService.accessAllowed(to.meta.roles);
        if (accessAllowed === true) {
          next();
        }
        else {
          next('/access-denied');
        }
      }
      else {
        await authService.login(to.path);
      }
      */
      next("/access-denied");
    }
    else {
      if(!hasQueryParams(to) && hasQueryParams(from)) {
        next(
          {
            //@ts-ignore
            name: to.name, 
            query: from.query
          });
      } else {
        next()
      }
    }
  }
});

export default router;
